'use strict';

var elementHeightEqualizer = require('element-height-equalizer'),
    $parallaxItems = $('.home-page-panel .background-image.parallax'),
    $spinningBottleVideoTag = $('.background-repeat');


$parallaxItems.each(function(index, thisParallaxItem) {
    thisParallaxItem.originalTop = thisParallaxItem.offsetTop;
});

$(window).scroll(function() {
    var delta = 0.15 * $(this).scrollTop();
    $parallaxItems.each(function(index, thisParallaxItem) {
        $(thisParallaxItem).css('top', thisParallaxItem.originalTop - delta);
    });
});

elementHeightEqualizer();

$spinningBottleVideoTag[0].load();
$spinningBottleVideoTag[0].play();


